import React from 'react'
import { Outlet, Link, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet-async";

//style 
import './../style/page/analysis.scss'

function Analysis() {
    const path = useLocation().pathname.split('/').pop()
    console.log(path)
    function activeStyled(val) {
        if (val === path || (path === 'analysis' && val === 'tests')) {
            return ('button active')
        }
        else {
            return ('button')
        }
    }

    return (
        <div className='analysis'>
            <Helmet>
                <title>Central Clinic | Analizlər</title>
                <meta name="description" content="Central Clinic 2022ci ildən etibarən Xırdalanda fəaliyyət göstərir." />
                {/* <meta name="keywords" content="Xirdalanda, Central Clinic,  Xırdalanda, Xırdalan, Xirdalan Tibb Mərkəzi " /> */}
            </Helmet>
            <div className="container">
                <div className="buttons">
                    <Link to='tests' className={activeStyled('tests')}>
                        A-dan Z-yə Testlər
                    </Link>
                    <Link to="lab_sections" className={activeStyled('lab_sections')}>
                        Laborator bölümlər üzrə
                    </Link>
                    <Link to='organs_packages' className={activeStyled('organs_packages')}>
                        Orqanlar üzrə müayinələr
                    </Link>
                </div>
                <Outlet />
            </div>
        </div>
    )
}

export default Analysis