import React, { useEffect, useState } from 'react'
import axios from "axios"
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom';

import Buttons from '../component/Buttons'

//style 
import './../style/page/checkup.scss'

function CheckUp() {
    const [category, setCategory] = useState('Hamısı')
    const [packets, setPackets] = useState([])
    const choosenPacketList = []

    useEffect(() => {
        axios.get(`https://centralclinic.az/api/checkup/az`)
            .then(response => {
                setPackets(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, ['az'])

    const cat = [
        {
            title: 'Kişi'
        },
        {
            title: 'Qadın'
        },
        {
            title: 'Uşaq'
        }
    ]
    choosenPacket()

    function choosenPacket() {
        if (category === 'Hamısı') {
            packets.map(packet => {
                choosenPacketList.push(
                    <Link to={`/checkup/${packet.slug}`} className='checkUpPacket'>
                        <div>
                            <img src={packet.image} alt={packet.slug} />
                        </div>
                        {/* <h3>{packet.title}</h3> */}
                    </Link>
                )
            })
        }
        else {
            packets.map(packet => {
                if (category === packet.category) {
                    choosenPacketList.push(
                        <div className='checkUpPacket'>
                            <div>
                                <img src={packet.image} alt={packet.slug} />
                            </div>
                            {/* <h3>{packet.title}</h3> */}
                        </div>
                    )
                }
            })
        }
    }

    return (
        <div className='CheckUp'>
            <Helmet>
                <title>Central Clinic | Check-up</title>
                <meta name="description" content="Central Clinic Check-up." />
                <meta name="keywords" content="Checkup paketlər, Check-up paketlər, Diabetik , Check-up müayinə , Endekronoloji check up  , Kardioloji check up , Ginekoloji check up , Uroloji check up, Terapevtik check up, Androloji check up, Pediatik check up , Süd vəzi check up" />
            </Helmet>
            <div className="container">
                <h2>Check-up paketlər</h2>
                <p>“Profilaktika müalicədən daha qənaətlidir” strategiyamıza əsaslanaraq hazırladığımız, laborator, instrumental və klinik müayinələrdən ibarət olan Check-up müayinə paketləri vasitəsilə sağlamlığınız, orqanizminizdəki narahatlıqlar və ya xəstəliklər haqqında məlumatlana bilərsiniz .</p>
                <Buttons data={cat} setCategory={setCategory} choosen={choosenPacket} active={category} />
                <div className='checkUpPacketList'>
                    {choosenPacketList}
                </div>
            </div>
        </div>
    )
}

export default CheckUp