import React from 'react'
import Location from './Location'

//style
import './../style/component/contacthome.scss'

//image
import waze from './../image/waze.webp'
import uber from './../image/uber.webp'
import yango from './../image/yango.webp'
import bolt from './../image/bolt.webp'

function ContactHome() {
    return (
        <div className='ContactHome'>
            <div className='text-buttons'>
                <div className='text'>
                    <h2>
                        Bizimlə əlaqə
                    </h2>
                    <h3>
                        Central Clinic
                    </h3>
                    <div>
                        <p className='bold'>Əlaqə nömrəsi:</p>
                        <a href='tel:+994 50 777 69 99'>+994 50 777 69 99</a>
                    </div>
                    <div>
                        <p className='bold'>Elektron adres:</p>
                        <a href='mailto:info@centralclinic.az'>info@centralclinic.az</a>
                    </div>
                    <div>
                        <p className='bold'>Ünvan:</p>
                        <a href='https://www.google.com/maps/dir/?api=1&destination=40.453413206199%2C49.73414182663&fbclid=IwAR143aN_cK8ZnvN7BCCSVpPS0dINKf7ATQYQKPYd0wcIdWd6WeKEQjDQe-I' target='_blank' rel="noopener noreferrer">Heydər Əliyev pr.30, Xırdalan, Azərbaycan</a>
                    </div>
                </div>
                <div className='map-buttons'>
                    {/* <a href='' target='_blank' className='uber map-button'>
                        <img src={uber} alt="uber" />
                    </a> */}
                    <a target='_blank' rel="noopener noreferrer" href="https://www.waze.com/az/live-map/directions/az/khirdalan/central-clinic?to=place.ChIJ77ANSbyFMEARan0mO4_fnso" className='waze map-button'>
                        <img src={waze} alt="waze" />
                    </a>
                    {/* <a href='' target='_blank' className='yango map-button'>
                        <img src={yango} alt="yango" />
                    </a>
                    <a href='' target='_blank' className='bolt map-button'>
                        <img src={bolt} alt="bolt" />
                    </a> */}
                </div>
            </div>
            <Location />
        </div>
    )
}

export default ContactHome