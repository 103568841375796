import React from 'react'
import { Link } from "react-router-dom"

//image
import youtube from './../image/youtube.png'
import linkedin from './../image/linkedin.png'
import instagram from './../image/instagram.png'
import facebook from './../image/facebook.svg'
import logo from './../image/logo.png'

//style
import './../style/component/footer.scss'

function Footer() {

  return (
    <footer>

      <div className="container">
        <Link to='/' className='logo'>
          <img src={logo} alt="logo" />
        </Link>
        <div className='copyright'>
          <p>Central Clinic © 2023</p>
          <p>Site by Starcom</p>
        </div>
        <div className='social'>
          <div className='youtube'>
            <img src={youtube} alt="youtube" onClick={() => { window.location.href = 'https://www.youtube.com/@centralclinicaz'; }} />
          </div>
          {/* <div className='linkedin'>
            <img src={linkedin} alt="linkedin" />
          </div> */}
          <div onClick={() => { window.location.href = 'https://www.instagram.com/centralclinic.az/'; }} className='instagram'>
            <img src={instagram} alt="instagram" />
          </div>
          <div onClick={() => { window.location.href = 'https://www.facebook.com/centralclinic.az/'; }} className='facebook'>
            <img src={facebook} alt="facebook" />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer