import React from 'react'

//style
import './../style/component/poliknikdetails.scss'

function PoliknikDetails(props) {
    return (
        <div className='PoliknikDetails'>
            <div className='row'>
                <div className='serviceName'>Xidmətin adı</div>
                <div className='servicePrice'>Qiymət AZN</div>
            </div>
            <h4>USM</h4>
            {props.data.map(res => {
                if ((res.service_name[0] === ' ' && res.service_name[1] === props.letter) || res.service_name[0] === props.letter) {
                    return (<div className='row'>
                        <div className='serviceName'>{res.service_name}</div>
                        <div className='servicePrice'>
                            <p>
                                {res.service_price}
                            </p>
                            <button onClick={() => {
                                let count = 0
                                if (props.basket.length === 0) {
                                    props.setBasket(prev => [...prev, res])
                                }
                                else {
                                    props.basket.map(e => {
                                        if (e.id === res.id) {
                                            return count = 1
                                        }
                                    })
                                }
                                if (count === 0 && props.basket.length !== 0) {
                                    props.setBasket(prev => [...prev, res])
                                }
                            }}>+</button>
                        </div>
                    </div>)
                }

            })}
        </div>
    )
}

export default PoliknikDetails