import React from 'react'

function SuccesLogin() {
    const user = JSON.parse(window.localStorage.getItem('user')) || []

    function submitHandler() {
        window.localStorage.setItem("user", JSON.stringify({}))
    }

    return (
        <form onSubmit={submitHandler} className='succesLogin'>
            <p>{user.name} {user.surname}</p>
            <button>Çıxış et</button>
        </form>
    )
}

export default SuccesLogin