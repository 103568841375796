import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";
import axios from "axios"

//style
import './../style/page/checkuppage.scss'

function CheckUpPage() {
    const [packets, setPackets] = useState([])
    const { checkupSlug } = useParams()

    useEffect(() => {
        axios.get(`https://centralclinic.az/api/checkup/az`)
            .then(response => {
                setPackets(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, ['az'])

    return (
        <div className='CheckUpPage'>
            {packets.map(packet => {
                if (packet.slug == checkupSlug) {
                    return (
                        <div className="container">
                            <Helmet>
                                <title>{`Central Clinic | ${packet.seo_title}`}</title>
                                <meta name="description" content={packet.seo_description} />
                                <meta name="keywords" content={packet.seo_keyword} />
                            </Helmet>
                            <img src={packet.image} alt={packet.slug} />
                            <div>
                            {parse(packet.content)}
                            </div>
                        </div>
                    )
                }
            })}


        </div>
    )
}

export default CheckUpPage