import React from 'react'

//style
import './../style/component/calculator.scss'

function Calculator(props) {
    let count = 1, sum = 0
    return (
        <div className='calculator'>
            <div className=''>
                Hesablama
            </div>
            <div>
                <p className='bold'>

                    Analizlərin sayı: {props.data.length}
                </p>
            </div>
            <div>
                <div>
                    {props.data.map(e => {
                        sum += e.service_price
                        return (
                            <div className='cal-analysis'>
                                <p>{count++}. {e.service_name}</p>
                                <p>{e.service_price} AZN</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='price'>
                <div>
                    <p className='bold'>Məbləğ</p>
                    <p>{sum} AZN</p>
                </div>
                {/* <div>
                    <p className='bold'>Endirim</p>
                    <p>0 AZN</p>
                </div> */}
                {/* <div>
                    <p className='bold'>Cəmi</p>
                    <p>25.00 AZN</p>
                </div> */}
            </div>
        </div>
    )
}

export default Calculator