import React, { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom"
import axios from "axios"
import { Helmet } from "react-helmet-async";

import AnalysisDetails from './AnalysisDetails'
import Letters from './Letters'

//style
import './../style/page/tests.scss'
import PoliknikDetails from './PoliknikDetails'
import Calculator from './Calculator'

function Tests() {
    const [category, setCategory] = useState('laborator')
    const [labData, setLabData] = useState([])
    const [polData, setPolData] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const show = (searchParams.get('search') !== '' && searchParams.get('search') !== null)
    const [basket, setBasket] = useState([])

    useEffect(() => {
        const data = JSON.parse(window.localStorage.getItem('basket'))
        if (data !== null && data.length !== 0) {
            data.map(e => {
                setBasket(prev => [...prev, e])
            })
        }
    }, [])

    useEffect(() => {
        window.localStorage.setItem('basket', JSON.stringify(basket))
    }, [basket])

    function swithcdetails() {
        switch (category) {
            case 'poliknik':
                return <PoliknikDetails data={polData} letter={searchParams.get('search')} setBasket={setBasket} basket={basket} />
            default:
                return <AnalysisDetails data={labData} letter={searchParams.get('search')} setBasket={setBasket} basket={basket} />
        }
    }

    useEffect(() => {
        axios.get(`https://centralclinic.az/api/laboratory-analysis/az`)
            .then(response => {
                setLabData(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, ['az'])

    useEffect(() => {
        axios.get(`https://centralclinic.az/api/policlinic-analysis/az`)
            .then(response => {
                setPolData(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, ['az'])


    function checkedInput(val) {
        if (val === category) {
            return "checked"
        }
        else {
            return null
        }
    }


    return (
        <div className='tests'>
            <Helmet>
                <title>Central Clinic | Test</title>
                <meta name="description" content="Central Clinic 2022ci ildən etibarən Xırdalanda fəaliyyət göstərir." />
                {/* <meta name="keywords" content="Xirdalanda, Central Clinic,  Xırdalanda, Xırdalan, Xirdalan Tibb Mərkəzi " /> */}
            </Helmet>
            <div>
                <div className='radio-buttons'>
                    <div>
                        <label htmlFor="laborator">Laborator analizlər</label>
                        <input onChange={() => {
                            setCategory('laborator')
                        }} id='laborator' type="radio" name='service' value='laborator' checked={checkedInput('laborator')} />
                    </div>
                    <div>
                        <label htmlFor="poliknik">Poliknik xidmətlər</label>
                        <input onChange={() => {
                            setCategory('poliknik')
                        }} id='poliknik' type="radio" name='service' value='poliknik' checked={checkedInput('poliknik')} />
                    </div>
                </div>
                <Letters setSearchParams={setSearchParams} searchParams={searchParams} />
            </div>

            {
                show ?
                    <div className='table'>
                        {swithcdetails()}
                        <Calculator data={basket} />
                    </div>
                    : null
            }
        </div>
    )
}

export default Tests