import React from 'react'

function Birthday(props) {
    const months = ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'Iyun', 'Iyul', 'Avqust', 'Sentyabr', 'Oktyabr', 'Noyabr', 'Dekabr']
    const days = [];
    const years = []
    for (let i = 1; i <= 31; i++) {
        days.push(<option value={i}>{i}</option>)
    }

    for (let i = 2023; i >= 1900; i--) {
        years.push(<option value={i}>{i}</option>)

    }
   
    return (
        <div className='birthday'>
            <h4>Doğum tarixi</h4>
            <div>
                <div className='form-group'>
                    <select name="birth_day" id="" onChange={props.changeHandler}>
                        <option value="">Gün</option>
                        {days}
                    </select>
                </div>
                <div className='form-group'>
                    <select name="birth_month" id="" onChange={props.changeHandler}>
                        <option value="">Ay</option>
                        {
                            months.map(month => {
                                return (
                                    <option value={month}>
                                        {month}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className='form-group'>
                    <select name="birht_year" id="" onChange={props.changeHandler}>
                        <option value="">İl</option>
                        {years}
                    </select>
                </div>
            </div>

        </div>
    )
}

export default Birthday