import React, { useEffect, useState } from 'react'
import axios from "axios"
import { Helmet } from "react-helmet-async";

import Buttons from '../component/Buttons'
import DoctorCard from '../component/DoctorCard'

//style
import './../style/page/doctors.scss'

function Doctors() {
  const [doctors, setDoctors] = useState([])
  const choosenDoctorList = []

  useEffect(() => {
    axios.get(`https://centralclinic.az/api/doctor/az`)
      .then(response => {
        setDoctors(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }, ['az'])

  choosenDoctor()

  function choosenDoctor() {
    doctors.map(doctor => {
      choosenDoctorList.push(
        <DoctorCard data={doctor} />
      )
    })
  }



  return (
    <div className='doctors'>
      <Helmet>
        <title>Central Clinic | Həkimlər</title>
        <meta name="description" content="Central CLinik klinikasında fəaliyyət göstərən həkimlər" />
        <meta name="keywords" content="Həkimlər , Terapiya , Ginekologiya , Dermatologiya , Endokrinologiya , Otorinolarinqologiya (LOR) , Laboratoriya , USM , Pediatiya , Kardiologiya" />
      </Helmet>
      <h1>Həkimlərimiz</h1>
      <div className='doctorlist'>
        {choosenDoctorList}
      </div>
    </div>
  )
}

export default Doctors