import React from 'react'
import Slider from "react-slick";

//style
import './../style/component/imageslider.scss'

function ImageSlider(props) {
    const images = props.data.slice()
    const main = images.at(props.index)
    images.splice(props.index, 1)
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    autoplaySpeed: 2000,
                    autoplay: true,
                }
            }
        ]
    };

    return (
        <div className='ImageSlider'>
            <svg onClick={() => {
                props.setShow(!props.show);
                document.body.style.overflow = 'scroll'
            }
            } className='cancel' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" /></svg>
            <Slider {...settings}>
                <div>
                    <img src={main} alt={props.slug} />
                </div>
                {images.map(image => {
                    return (
                        <div>
                            <img src={image} alt={props.slug} />
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default ImageSlider