import React from 'react'

//style
import './../style/component/letters.scss'

function Letters(props) {
    
    const letters = [
        'A',
        'B',
        'C',
        'Ç',
        'D',
        'E',
        'Ə',
        'F',
        'G',
        'H',
        'X',
        'İ',
        'J',
        'K',
        'Q',
        'L',
        'M',
        'N',
        'O',
        'Ö',
        'P',
        'R',
        'S',
        'Ş',
        'T',
        'U',
        'Ü',
        'V',
        'Y',
        'Z',
    ]

    function activeStyle(letter) {
        if (props.searchParams.get('search') === letter) {
            return ("letter active")
        }
        else {
            return ("letter")
        }
    }

    return (
        <div className='letters'>
            {
                letters.map(letter => {
                    return (<div onClick={()=>{
                        props.setSearchParams({search : letter}  )
                    }} className={activeStyle(letter)}>{letter}</div>)
                })
            }
        </div>
    )
}

export default Letters