import React from 'react'
import { Helmet } from "react-helmet-async";

//style 
import './../style/page/aboutus.scss'
function AboutUs() {
    return (
        <div className='about-us'>
            <Helmet>
                <title>Central Clinic | Haqqımızda</title>
                <meta name="description" content="Central Clinic 2022ci ildən etibarən Xırdalanda fəaliyyət göstərir." />
                <meta name="keywords" content="Xirdalanda, Central Clinic,  Xırdalanda, Xırdalan, Xirdalan Tibb Mərkəzi " />
            </Helmet>
            <h1>Haqqımızda</h1>
            <div className="container">
                <p>
                    2022-ci ildə Xırdalanda istifadəyə verilən “Central Clinic“ Tibb Mərkəzi müasir arxitekturası, qabaqcıl texnologiya cihazları, rahat pasient otaqları və poliklinikaları ilə pasientlərin özlərini xüsusi və təhlükəsiz hiss etmələri üçün bütün detallar nəzərə alınmaqla dizayn edilmişdir.
                </p>
                <p>
                    Müasir infrastrukturu və üstün tibbi texnologiyası ilə beynəlxalq sağlamlıq standartlarında xidmət göstərən  “Central Clinic “ Tibb Mərkəzi Xırdalan şəhərində 1500 m2 sahədə inşa edilib.
                </p>
                <p>
                    Kardiologiya, Ginekologiya, Pediatriya, Urologiya, Anrologiya, Dermotologiya, Otoloringologiya, Nevrologiya, Endokrinologiya, Radiologiya(usm), Terapiya və Laboratoriya sahələrində  tam təchiz olunmuş klinika diaqnostika və müalicə xidmətləri göstərir.
                </p>
                <p>
                    “Central Clinic“ Tibb Mərkəzi sizə təqdim etdiyi sağlamlıq xidmətlərinin hər sahəsində insanlara verdiyi dəyəri hiss etdirir. Klinikamızda bütün diaqnostika və müalicə xidmətləri multidissiplinar yanaşma ilə təklif olunur.
                </p>
                <p>
                    “Central Clinic“ Tibb Mərkəzi təcrübəli həkim heyəti, gülərüz personalı, insanı güvənli və təhlükəsiz hiss etdirən üstün infrastrukturu və texniki avadanlığı ilə xidmətinizdədir.
                </p>
            </div>
        </div>
    )
}

export default AboutUs