import React  from 'react'

//style
import './../style/component/phonenumber.scss'

function PhoneNumber(props) {
    function changeHandler(event) {
        props.setNumber(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
    }
    return (
        <div className='PhoneNumber'>
            <input type="text" name="country" id="" value={props.number.country} disabled onChange={changeHandler} />
            <select name="operator" id="" value={props.number.operator} onChange={changeHandler}>
                <option value="50">50</option>
                <option value="51">51</option>
                <option value="55">55</option>
                <option value="70">70</option>
                <option value="77">77</option>
                <option value="99">99</option>
            </select>
            <input type="tel" name="number" maxLength={7} value={props.number.number} onChange={changeHandler} />
        </div>
    )
}

export default PhoneNumber