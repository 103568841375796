import React, { useState, useRef, useEffect } from 'react';
import Hamburger from './Hamburger'
import { Link, useLocation, useNavigate } from "react-router-dom"

//image
import logo from './../image/logo.png'

//style
import './../style/component/header.scss'
import Languages from './Languages';
import Account from './Account';

function Header() {
  const [state, setState] = useState(false)
  const [basket, setBasket] = useState([])
  const [account, setAccount] = useState(false)
  const userAccount = useRef(null)
  const path = useLocation().pathname
  const navigate = useNavigate()
  const [profil, setProfil] = useState(false)

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem('user')) || {}
    if (Object.keys(user).length !== 0) {
      setProfil(true)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
  }, [])

  const handleClickOutside = (e) => {
    if (!userAccount.current.contains(e.target)) {
      setAccount(false)
    }
  }

  setInterval(() => {
    setBasket(JSON.parse(window.localStorage.getItem('basket')) || [])
  }, 500)

  // const addClassScroll = () => {
  //   const scroll = document.documentElement.scrollTop
  //   if (scroll > 100) {
  //     setState(true)
  //   }
  //   else {
  //     setState(false)
  //   }
  // }
  // window.addEventListener('scroll', addClassScroll)

  return (
    // <div className={state ? 'header bg-white' : 'header'}>
    <div className='header'>
      <div className="container">
        <Link to='/' className='logo'>
          <img src={logo} alt="logo" />
        </Link>
        <div>
          <div>
            <a href="tel:+994 50 777 69 99" className='call-center'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z" /></svg>
              <p>Çağrı mərkəzi</p>
            </a>
          </div>
          <Link to={`/basket`} className='basket'>
            {basket.length ? <div>{basket.length} </div> : null}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM252 160c0 11 9 20 20 20h44v44c0 11 9 20 20 20s20-9 20-20V180h44c11 0 20-9 20-20s-9-20-20-20H356V96c0-11-9-20-20-20s-20 9-20 20v44H272c-11 0-20 9-20 20z" /></svg>
          </Link>
          <div className='account' ref={userAccount}>
            <svg onClick={() => {
              setAccount(!account)
            }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
            {account ?
              <Account profil={profil} />
              : null}
          </div>
          <Hamburger />
          {/* <Languages /> */}
        </div>
      </div>


    </div>
  )
}

export default Header