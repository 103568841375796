import React, { useEffect, useState } from 'react'
import { Link, useLocation, Outlet, useNavigate } from "react-router-dom"
import axios from "axios"
import { Helmet } from "react-helmet-async";

//style
import './../style/page/laboratory.scss'

//image
import laboratoryImage from './../image/laboratory.webp'

function Laboratory() {
    const [laboratory, setLaboratory] = useState([])
    const [subCat, setSubCat] = useState([])
    const path = useLocation().pathname
    const navigate = useNavigate()

    function activeStyled(val) {
        if (val === path.split('/')[2]) {
            return ('button active')
        }
        else {
            return ('button')
        }
    }

    useEffect(() => {
        if (path.split('/').pop() === "laboratory") {
            navigate(`${path}/preparation-for-analyses/qan-numunesi`)
        }
        else if (path.split('/').pop() === "preparation-for-analyses") {
            navigate(`${path}/qan-numunesi`)
        }
    })

    useEffect(() => {
        axios.get(`https://centralclinic.az/api/main-category/az`)
            .then(response => {
                response.data.map(main => {
                    if (main.slug === 'laboratory') {
                        setLaboratory(main)
                    }
                })
            })
            .catch(error => {
                console.log(error)
            })
    }, ['az'])

    useEffect(() => {
        axios.get(`https://centralclinic.az/api/sub-category/az`)
            .then(response => {
                setSubCat(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, ['az'])

    return (
        <div className='Laboratory'>
            <Helmet>
                <title>Central Clinic | Laboratoriya</title>
                <meta name="description" content="Central Clinic 2022ci ildən etibarən Xırdalanda fəaliyyət göstərir." />
                {/* <meta name="keywords" content="Xirdalanda, Central Clinic,  Xırdalanda, Xırdalan, Xirdalan Tibb Mərkəzi " /> */}
            </Helmet>
            <div className="banner">
                <img src={laboratoryImage} alt="laboratoriya" />
                <div className="buttons">
                    {subCat.map(sub => {
                        if (sub.main_category === laboratory.id) {
                            return (
                                <Link to={`${sub.slug}`} className={activeStyled(sub.slug)}>
                                    {sub.title}
                                </Link>
                            )
                        }
                    })}
                </div>
            </div>
            <Outlet />
        </div>
    )
}

export default Laboratory