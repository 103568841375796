import React, { useState } from 'react'
import Cookies from 'js-cookie';

import SuccesLogin from './SuccesLogin'

//style
import './../style/component/login.scss'

function Login(props) {
    const [login, setLogin] = useState({
        username: '',
        password: ''
    })

    function submitHandler(event) {
        let count = 0
        props.users.map(res => {
            if (res.email === login.username && res.password === login.password) {
                window.localStorage.setItem("user", JSON.stringify(res))
                Cookies.set('user', [ res.name, res.surname ], { expires: 7 });
                count = 1
            }
            else if (count === 0) {
                event.preventDefault()
            }
        })
    }

    function changeHandler(event) {
        setLogin(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
    }

    return (
        <div>
            {
                props.profil ? <SuccesLogin />
                    :
                    <form onSubmit={submitHandler} >
                        <div className='form-group'>
                            <label htmlFor="username">E-mail address və ya mobil nömrə</label>
                            <input id='username' type="text" value={login.username} name="username" onChange={changeHandler} />
                        </div>
                        <div className='form-group'>
                            <label htmlFor="password">Şifrəni daxil et</label>
                            <input id='password' type="password" value={login.password} name="password" onChange={changeHandler} />
                        </div>

                        <button type='submit'>Daxil ol</button>
                        <p>Hesabınız yoxdursa <span onClick={() => {
                            props.setLogin(false)
                        }}>Qeydiyyatdan keçin!</span></p>
                    </form>
            }
        </div>
    )
}

export default Login