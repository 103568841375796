import React from 'react'
import Slider from "react-slick";
import { Link } from "react-router-dom"

function BannerButtom(props) {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            }]
    };
    return (
        <div className='BannerButtom'>
        
                {props.mainCat.slice(0)
                    .reverse().map(main => {
                        if (main.is_home) {
                            return (

                                <div className='homeButton'>
                                    <Link to={`/${main.slug}`} key={main.id}>
                                        <img src={main.icon} alt={main.title} />
                                        <h3>{main.title}</h3>
                                    </Link>
                                </div>
                            )
                        }
                    })}

        </div>
    )
}

export default BannerButtom