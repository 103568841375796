import React, { useState } from 'react'
import axios from "axios"
import { Helmet } from "react-helmet-async";

import Birthday from '../component/Birthday'

//style
import './../style/page/onlineresgiter.scss'

function OnlineRegister() {
    const [register, setRegister] = useState({
        name: '', surname: '', father_name: '', fin: '',
        birth_day: '', birth_monthbirth_month: '', birht_year: '',
        email: '', mobile_phone: '', gender: '', passport: ''
    })

    function changeHandler(e) {
        console.log(e.target.name, e.target.value)
        setRegister(prev => ({ ...prev, [e.target.name]: e.target.value }))
        console.log(register)
    }

    function submitHandler() {
        axios.post("https://centralclinic.az/api/register/", {
            "name": register.name,
            "surname": register.surname,
            "father_name": register.father_name,
            "fin": register.fin,
            "birth_day": register.birth_day,
            "birth_month": register.birth_month,
            "birth_year": register.birht_year,
            "email": register.email,
            "mobile_phone": register.mobile_phone,
            "gender": register.gender,
        })
            .then(res => { console.log(res) })
            .catch(error => { console.log(error) })
    }

    return (
        <div className='OnlineRegister'>
            <Helmet>
                <title>Central Clinic | Online Qeydiyyat</title>
                <meta name="description" content="Central Clinic online qeydiyyat" />
                <meta name="keywords" content="Online qeydiyyat" />
            </Helmet>
            <div className="container">
                <h2>
                    Onlayn qeydiyyat
                </h2>
                <p>

                </p>
                <div className='form'>
                    <form onSubmit={submitHandler}>
                        <div className='form-group'>
                            <input type="text" placeholder='Ad' value={register.name} name='name' onChange={changeHandler} />
                        </div>
                        <div className='form-group'>
                            <input type="text" placeholder='Soyad' value={register.surname} name='surname' onChange={changeHandler} />
                        </div>
                        <div className='form-group'>
                            <input type="text" placeholder='Ata adı' value={register.father_name} name='father_name' onChange={changeHandler} />
                        </div>

                        <div className='form-group'>
                            <input type="text" placeholder='FIN' value={register.father_name} name='fin' onChange={changeHandler} />
                        </div>
                        <Birthday changeHandler={changeHandler} />

                        <div className='form-group width-100'>
                            <input type="email" placeholder='Email' value={register.email} name='email' onChange={changeHandler} />
                        </div>
                        <div className='form-group width-100'>
                            <input type="phone" placeholder='Mobil nömrə' value={register.mobile_phone} name='mobile_phone' onChange={changeHandler} />
                        </div>
                        <div className='identification'>
                            <div className='gender'>
                                <div>
                                    <label htmlFor="Kişi">Kişi</label>
                                    <input type="radio" value='Kişi' id='Kişi' name="gender" onChange={changeHandler} />
                                </div>
                                <div>
                                    <label htmlFor="Qadın">Qadın</label>
                                    <input type="radio" value='Qadın' id='Qadın' name="gender" onChange={changeHandler} />
                                </div>
                            </div>
                            <div className='passport'>
                                <input type="file" id='passport' value={register.passport} name='passport' onChange={changeHandler} />
                                <span>Şəxsiyyət vəsiqəsini yüklə:</span>
                                <label htmlFor="passport">Yüklə</label>
                            </div>
                        </div>
                        <button type='submit'>Qeydiyyat</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default OnlineRegister