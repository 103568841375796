import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './../style/component/register.scss';
import PhoneNumber from './PhoneNumber';

function Register(props) {
    const [user, setUser] = useState({
        name: '',
        surname: '',
        email: '',
        mobile_phone: '',
        password: '',
        confirmPassword: '',
        passwordsMatch: true
    });

    const [number, setNumber] = useState({
        country: '+994',
        operator: '050',
        number: ''
    })

    function submitHandler(event) {
        setUser(prev => ({ ...prev, mobile_phone: `${number.country}${number.operator}${number.number}` }))
        props.users.map(res => {
            if (res.email === user.email || res.mobile_phone === user.mobile_phone) {
                alert("email-dən daha evvel istifade etmisiz")
                event.preventDefault()
                return
            }
        })

        if (user.password !== user.confirmPassword) {
            event.preventDefault()
            setUser(prevState => ({ ...prevState, passwordsMatch: false }));
            return;
        }

        axios
            .post('https://centralclinic.az/api/user/', {
                name: user.name,
                surname: user.surname,
                email: user.email,
                mobile_phone: user.mobile_phone,
                password: user.password
            })
            .then(res => {
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
    }

    function changeHandler(event) {
        setUser(prev => ({ ...prev, mobile_phone: `${number.country}${number.operator}${number.number}` }))
        setUser(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
    }


    return (
        <form onSubmit={submitHandler} className="register">
            <div className="form-group">
                <label htmlFor="name">Ad</label>
                <input id="name" type="text" value={user.name} name="name" onChange={changeHandler} />
            </div>
            <div className="form-group">
                <label htmlFor="surname">Soyad</label>
                <input id="surname" type="text" value={user.surname} name="surname" onChange={changeHandler} />
            </div>
            <div className="form-group">
                <label htmlFor="email">E-mail address</label>
                <input id="email" type="email" value={user.email} name="email" onChange={changeHandler} />
            </div>
            <div className="form-group">
                <label htmlFor="mobile">Mobil nömrə</label>
                <PhoneNumber setNumber={setNumber} number={number} />
            </div>
            <div className="form-group">
                <label htmlFor="password">Şifrəni daxil et</label>
                <input id="password" type="password" value={user.password} name="password" onChange={changeHandler} />
            </div>
            <div className="form-group">
                <label htmlFor="confirmPassword">Şifrəni təsdiqlə</label>
                <input
                    id="confirmPassword"
                    type="password"
                    value={user.confirmPassword}
                    name="confirmPassword"
                    onChange={changeHandler}
                />
                {!user.passwordsMatch && <p>Passwords do not match</p>}
            </div>
            <p>Hesabınız varsa <span
                onClick={() => {
                    props.setLogin(true);
                }}
            >
                Daxil olun!
            </span>
            </p>
            <button>Qeydiyyat keç</button>
        </form>
    );
}

export default Register;
