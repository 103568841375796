import React from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";

//style
import './../style/page/preparationanalyses.scss'

function PreparationAnalyses() {
  const path = useLocation().pathname.split('/').pop()

  function activeStyled(val) {
    if (val === path) {
      return ('analysesActive')
    }
    else {
      return ('')
    }
  }

  return (
    <div className='PreparationAnalyses'>
      <div className="container">
        <div className='buttons'>
          <Link className={activeStyled("qan-numunesi")} to={`qan-numunesi`}>
            Qan nümunəsi
          </Link>
          <Link className={activeStyled("sidik-numunesi")} to={`sidik-numunesi`}>
            Sidik nümunəsi
          </Link>
          <Link className={activeStyled("necis-numunesi")} to={`necis-numunesi`}>
            Nəcis nümunəsi
          </Link>
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default PreparationAnalyses;
