import React, { useEffect, useState } from 'react'
import axios from 'axios'
import parse from 'html-react-parser';
import Slider from "react-slick";
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async";


//style
import './../style/page/department.scss'

function Department() {
    const [departments, setDepartments] = useState([])
    const [doctors, setDoctors] = useState([])
    const { department } = useParams()



    useEffect(() => {
        axios.get(`https://centralclinic.az/api/department/az`).then(response => {
            setDepartments(response.data)
        })
    }, ['az'])

    useEffect(() => {
        axios.get(`https://centralclinic.az/api/doctor/az`).then(response => {
            setDoctors(response.data)
        })
    }, ['az'])



    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (
        <div>
            {departments.map(dep => {
                if (dep.slug === department) {
                    return (
                        <div className='Department'>
                            <Helmet>
                                <title>{`Central Clinic | ${dep.title}`} </title>
                                <meta name="description" content="Central Clinic 2022ci ildən etibarən Xırdalanda fəaliyyət göstərir." />
                                {/* <meta name="keywords" content="Xirdalanda, Central Clinic,  Xırdalanda, Xırdalan, Xirdalan Tibb Mərkəzi " /> */}
                            </Helmet>
                            <div className='banner'>
                                <img src={dep.image} alt={dep.slug} />
                            </div>
                            <div className='departmentInfo'>
                                <div className='depInfo'>
                                    <h2>
                                        {dep.title}
                                    </h2>
                                    <div>
                                        {parse(dep.description)}
                                    </div>
                                </div>
                                <div className='depDoctors'>
                                    <Slider {...settings}>
                                        {doctors.map(doctor => {
                                            if (doctor.department === dep.title) {
                                                return (
                                                    <div className='depDoctorInfo'>
                                                        <img src={doctor.main_image} alt={doctor.slug} />
                                                        <h1>{doctor.full_name}</h1>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
        </div>


    )
}

export default Department