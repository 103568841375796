import React, { useState } from 'react'
import BiomaterialType from './BiomaterialType'

//style
import './../style/component/analysisdetails.scss'

function AnalysisDetails(props) {
    const [type, setType] = useState(null)

    return (
        <div className='AnalysisDetails'>

            <table>
                <tr className='row'>
                    <td className='serviceName'>Xidmətin adı (Müayinə müddəti)</td>
                    <td className='serviceKind'>Biomaterialın növü</td>
                    <td className='servicePrice'>Qiymət AZN</td>
                </tr>
                {
                    props.data.map(res => {
                        if ((res.service_name[0] === ' ' && res.service_name[1] === props.letter) || res.service_name[0] === props.letter) {
                            const array = res.biomaterial_type.split(';')
                            return (<tr className='row'>
                                <td className='serviceName'>{res.service_name} ({res.service_time})</td>
                                <td className='serviceKind'>
                                    <BiomaterialType data={array} setType={setType} />
                                </td>
                                <td className='servicePrice'>
                                    <p>
                                        {res.service_price}
                                    </p>
                                    <button onClick={() => {
                                        let count = 0
                                        if (props.basket.length === 0) {
                                            props.setBasket(prev => [...prev,
                                            {
                                                id: res.id,
                                                service_code: res.service_code,
                                                service_name: res.service_name,
                                                biomaterial_type: type || array[0],
                                                service_time: res.service_time,
                                                service_price: res.service_price,
                                            }])
                                        }
                                        else {
                                            props.basket.map(e => {
                                                if (e.service_code === res.service_code) {
                                                    return count = 1
                                                }
                                            })
                                        }
                                        if (count === 0 && props.basket.length !== 0) {
                                            props.setBasket(prev => [...prev,
                                            {
                                                id: res.id,
                                                service_code: res.service_code,
                                                service_name: res.service_name,
                                                biomaterial_type: type || array[0],
                                                service_time: res.service_time,
                                                service_price: res.service_price,
                                            }])
                                        }
                                    }}>+</button>
                                </td>
                            </tr>)
                        }
                    })
                }
            </table>

        </div>
    )
}

export default AnalysisDetails