import React from 'react'

function EResult() {
  return (
    <div className='EResult'>
        <p>Tezliklə xidmətinizdə...</p>
    </div>
  )
}

export default EResult