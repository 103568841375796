import React from 'react'
import { Link } from "react-router-dom"
import parse from 'html-react-parser';

//style
import './../style/component/doctorCard.scss'

function DoctorCard(props) {
    const trimmedExperience = props.data.experience.slice(0, 300)  + " ..."; 
    return (
        <Link to={`/doctors/${props.data.slug}`} className='doctor-card'>
            <div className='doctor-card-front'>
                <div>
                    <img src={props.data.main_image} alt={props.data.full_name} />
                    <h2>{props.data.full_name}</h2>
                </div>
                <h3>{props.data.speciality}</h3>
            </div>
            <div className="doctor-card-back">
                {
                    parse(trimmedExperience)
                }
                <div className='button'>
                    Daha Ətraflı
                </div>
            </div>
        </Link>
    )
}

export default DoctorCard