import React from 'react'



function Buttons(props) {
  
  function activeStyle(index) {
    if (props.active === index) {
      return ("button active")
    }
    else {
      return ("button")
    }
  }

  return (
    <div className='buttons'>
      <div className={activeStyle('Hamısı')} onClick={() => {
        props.setCategory('Hamısı')
        props.choosen()
      }}>
        Hamısı
      </div>
      {
        props.data.map(cat => {
          return (
            <div className={activeStyle(cat.title)} onClick={() => {
              props.setCategory(cat.title)
              props.choosen()
            }}>
              {cat.title}
            </div>
          )
        })
      }
    </div>
  )
}

export default Buttons