import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import axios from "axios"
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";

import YoutubeVideo from '../component/YoutubeVideo'

//style
import './../style/page/doctorpage.scss'
import ImageSlider from '../component/ImageSlider'


function DoctorPage() {
  const { doctorSlug } = useParams()
  const [doctors, setDoctors] = useState([])
  const [show, setShow] = useState(() => {
    if (window.innerWidth <= 900) {
      return true
    }
    else {
      return false
    }
  })
  const [index, setindex] = useState([])
  const doctorImage = []

  useEffect(() => {
    axios.get(`https://centralclinic.az/api/doctor/az`)
      .then(response => {
        setDoctors(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }, ['az'])

  return (
    <div className='DoctorPage'>

      {
        doctors.map(doctor => {
          if (doctorSlug === doctor.slug) {
            doctorImage.push(doctor.main_image)
            doctorImage.push(doctor.image_1)
            doctorImage.push(doctor.image_2)
            doctorImage.push(doctor.image_3)
            doctorImage.push(doctor.image_4)
            return (
              <div>
                <Helmet>
                  <title>{`Central Clinic | ${doctor.full_name}`} </title>
                  <meta name="description" content="Central Clinic 2022ci ildən etibarən Xırdalanda fəaliyyət göstərir." />
                  <meta name="keywords" content={`${doctor.full_name} , ${doctor.speciality}`} />
                </Helmet>
                <div className='galery'>
                  <h1>{doctor.full_name}</h1>
                  <h2>{doctor.speciality}</h2>
                  {show ?
                    <ImageSlider setShow={setShow} show={show} data={doctorImage} index={index} slug={doctor.slug} />
                    :
                    <div className="container">
                      <div>
                        <div className='images'>
                          <img onClick={() => {
                            setindex(0);
                            setShow(true);
                            window.scroll(0, 0);
                            document.body.style.overflow = 'hidden'
                          }} src={doctor.main_image} alt={doctor.full_name} />
                          <img onClick={() => {
                            setindex(1);
                            setShow(true);
                            window.scroll(0, 0);
                            document.body.style.overflow = 'hidden'
                          }} src={doctor.image_1} alt={doctor.full_name} />
                          <img onClick={() => {
                            setindex(2);
                            setShow(true);
                            window.scroll(0, 0);
                            document.body.style.overflow = 'hidden'
                          }} src={doctor.image_2} alt={doctor.full_name} />
                          <img onClick={() => {
                            setindex(3);
                            setShow(true);
                            window.scroll(0, 0);
                            document.body.style.overflow = 'hidden'
                          }} src={doctor.image_3} alt={doctor.full_name} />
                          <img onClick={() => {
                            setindex(4);
                            setShow(true);
                            window.scroll(0, 0);
                            document.body.style.overflow = 'hidden'
                          }} src={doctor.image_4} alt={doctor.full_name} />
                        </div>
                      </div>
                    </div>}
                </div>
                <div className='doctorInformation'>
                  <div className="container">
                    {/* <div className='workTime'>
                      <p className='title'>İş saatları:</p>
                      <div>
                        {doctor.working_hours.split(';').map(time => {
                          return (<div>{time.split(',').map(t => {
                            return (<p>{t}</p>)
                          })}</div>)
                        })}
                      </div>
                    </div> */}
                    <div className='information'>
                      <p className='title'>Haqqında</p>
                      <div>
                        {parse(doctor.experience)}
                      </div>
                    </div>
                  </div>
                </div>


                <div className='video'>
                  <div className="container">
                    <YoutubeVideo link={doctor.video_link} />
                  </div>
                </div>
              </div>
            )
          }
        })
      }
    </div>
  )
}

export default DoctorPage