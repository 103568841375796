import React, { useState } from 'react'
import axios from "axios"

//style
import './../style/page/basket.scss'

function Basket() {
    const [basket, setBasket] = useState(JSON.parse(window.localStorage.getItem('basket')) || [])
    const user = JSON.parse(window.localStorage.getItem("user")) || {}

    function sendBasket() {
        if (Object.keys(user).length !== 0) {
            const obj = { item: JSON.stringify(basket).replaceAll("'", '"') ,
                            user_id : user.id}
            setBasket([])
            window.localStorage.removeItem("basket")
            axios.post("https://centralclinic.az/api/basket/", obj)
                .then(res => { console.log(res) })
                .catch(error => { console.log(error) })
        }
        else {
            alert('Sayta daxil olun ve ya qeydiyyatdan kecin')
        }

    }

    return (
        <div className='Basket'>
            <div className="container">
                {basket.length ?
                    <div>
                        <div>
                            <div className='row'>
                                <div className='serviceName'>Xidmətin adı (Müayinə müddəti)</div>
                                <div className='serviceKind'>Biomaterialın növü</div>
                                <div className='servicePrice'>Qiymət AZN</div>
                            </div>
                            {basket.map(res => {
                                return (
                                    <div className='row'>
                                        <div className='serviceName'>{res.service_name}
                                            <span>
                                                ({res.biomaterial_type})
                                            </span>
                                        </div>
                                        <div className='serviceKind'>{res.biomaterial_type}</div>
                                        <div className='servicePrice'><p>{res.service_price} AZN</p>
                                            <div className='cancel' onClick={() => {
                                                let newArray = basket.filter((item) => item !== res)
                                                setBasket(newArray)
                                                localStorage.setItem('basket', JSON.stringify(newArray));
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}

                        </div>
                        <div className='send-button' onClick={sendBasket}>Göndər</div>
                    </div>

                    : <div>
                        Səbətiniz boşdur
                    </div>}
            </div>
        </div>
    )
}

export default Basket