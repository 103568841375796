import React, { useEffect, useState, useRef } from 'react'
import axios from "axios"
import { Helmet } from "react-helmet-async";

import DoctorHome from '../component/DoctorHome'
import ContactHome from '../component/ContactHome'
import BannerButtom from '../component/BannerButtom'
import OurDeparment from '../component/OurDepartment'
import UpButton from '../component/UpButton'
import Innovations from '../component/Innovations'

//style
import './../style/page/home.scss'

//image
import homebanner from './../image/home-page-banner.webp'

function Home() {
    const [mainCat, setMainCat] = useState([])
    const parentRef = useRef()

    useEffect(() => {
        axios.get(`https://centralclinic.az/api/main-category/az`)
            .then(response => {
                setMainCat(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, ['az'])

    return (
        <div className='home' ref={parentRef} id="home">
            <Helmet>
                <title>Central Clinic</title>
                <meta name="description"
                    content="2022-ci ildə Xırdalanda istifadəyə verilən “Central Clinic“ Tibb Mərkəzi müasir arxitekturası, qabaqcıl texnologiya cihazları, rahat pasient otaqları və poliklinikaları ilə pasientlərin özlərini xüsusi və təhlükəsiz hiss etmələri üçün bütün detallar nəzərə alınmaqla dizayn edilmişdir." />
                <meta name="keywords" content="Terapiya , Ginekologiya , Dermatologiya , Endokrinologiya , Otorinolarinqologiya (LOR) , Laboratoriya , USM , Pediatiya , Kardiologiya , Heydər Əliyev Prospekti 30" />
            </Helmet>
            <div>
                <BannerButtom mainCat={mainCat} />
                <div className='banner'>
                    <img src={homebanner} alt="Home Banner" />
                </div>
            </div>
            <DoctorHome />
            <Innovations />
            <OurDeparment />
            <ContactHome />
            <UpButton />
        </div>
    )

}

export default Home