import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import axios from "axios"
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";

//style
import './../style/page/polyclinic.scss'

function Polyclinic() {
    const [departments, setDepartment] = useState([])

    useEffect(() => {
        axios.get(`https://centralclinic.az/api/department/az`)
            .then(response => {
                setDepartment(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    return (
        <div className='polyclinic'>
            <Helmet>
                <title>Central Clinic | Poliklinika</title>
                <meta name="description" content="Central Clinic 2022ci ildən etibarən Xırdalanda fəaliyyət göstərir." />
                {/* <meta name="keywords" content="Xirdalanda, Central Clinic,  Xırdalanda, Xırdalan, Xirdalan Tibb Mərkəzi " /> */}
            </Helmet>
            <h1>Poliklinika</h1>
            <div className="departments">
                {departments.map(department => {
                    return (
                        <div className='department' key={department.id} >
                            <div className="departmentBG">
                                <img src={department.icon} alt={department.slug} />
                            </div>
                            <div className='image'>
                                <img src={department.icon} alt={department.slug} />
                            </div>
                            <div className='text'>
                                <h1>
                                    {department.title}
                                </h1>
                                <div>
                                    <p>
                                        {parse(department.short_description)}
                                    </p>
                                    <Link to={`/polyclinic/${department.slug}`}>
                                        Ətraflı
                                    </Link>
                                </div>
                            </div>
                        </div>)
                }
                )}
            </div>
        </div>
    )
}

export default Polyclinic