import React, { useState, useRef } from 'react';

//image
import loyalCard from './../image/SaytvideosuCentral.mp4'
import innovation from './../image/innovation.webp'

//style
import './../style/component/innovations.scss'


function Innovations() {
    const [checkPlay, setCheckPlay] = useState(true);
    const videoRef = useRef(null);

    const playVideo = () => {
        const video = videoRef.current;
        console.log("SADasda")
        if (video.paused) {
            setCheckPlay(false);
            video.play();
        }
        else {
            video.pause();
            setCheckPlay(true);
        }
    };

    return (
        <div className="innovations">
            <div className="innovation">
                {checkPlay ? (
                    <div onClick={playVideo}>
                        <img src={innovation} alt="innovation"/>
                        <div className='playButton'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                            </svg>
                        </div>
                    </div>
                ) : null}
                <video ref={videoRef} muted loop controls={checkPlay ? false : true}>
                    <source src={loyalCard} type="video/mp4" />
                </video>
            </div>
        </div>
    );
}

export default Innovations;
