import React, { useState, useEffect } from 'react'
import axios from 'axios';

import Login from './Login'
import Register from './Register'

function Account(props) {
    const [login, setLogin] = useState(true)
    const [users, setUsers] = useState([])

    useEffect(() => {
        axios.get("https://centralclinic.az/api/user/").then(response => { setUsers(response.data) })
    }, [])

   

    return (
        <div className='user-account'>
            {login ?
                <Login setLogin={setLogin} users={users} profil={props.profil} />
                :
                <Register setLogin={setLogin} users={users} />
            }
        </div>
    )
}

export default Account