import React from 'react'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'

//style
import './../style/component/location.scss'

function Location() {
    return (
        <div className='centralMap'>
            <MapContainer center={[40.4528693, 49.7325661]} zoom={16} scrollWheelZoom={false}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[40.4528652, 49.7332612]}>
                </Marker>
            </MapContainer>
        </div>
    )
}

export default Location