import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import axios from "axios"
import parse from 'html-react-parser';

//style 
import './../style/component/ourdeparment.scss'

function OurDeparment() {
  const [departments, setDepartment] = useState([])
  const [departmentCount, setDepartmentCount] = useState(() => {
    if (window.innerWidth > 900) return (8)
    else return (4)
  })
  const [buttonText, setbuttonText] = useState('Daha çox')


  useEffect(() => {
    axios.get(`https://centralclinic.az/api/department/az`)
      .then(response => {
        setDepartment(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  function changeButton() {
    if (buttonText === 'Daha çox') {
      setDepartmentCount(departments.length)
      setbuttonText('Daha az')
    }
    else {
      if (window.innerWidth > 900) setDepartmentCount(8)
      else setDepartmentCount(4)
      setbuttonText('Daha çox')
    }
  }

  return (
    <div className='OurDeparment'>
      <h2>
        Bizim şöbələr
      </h2>
      <div className="departments">
        {departments.map((department, index) => {
          if (index < departmentCount) {
            return (
              <div className='department' key={department.id} >
                <div className="departmentBG">
                  <img src={department.icon} alt={department.slug} />
                </div>
                <div className='image'>
                  <img src={department.icon} alt={department.slug} />
                </div>
                <div className='text'>
                  <h3>
                    {department.title}
                  </h3>
                  <div>
                    <p>
                      {parse(department.short_description)}
                    </p>
                    <Link to={`/polyclinic/${department.slug}`}>
                      Ətraflı
                    </Link>
                  </div>
                </div>
              </div>)
          }
        })}
      </div>
      <div className='button' onClick={changeButton}>{buttonText}</div>
    </div>
  )
}

export default OurDeparment
