import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { Helmet } from "react-helmet-async";

function PreparationUrine() {
  const [preAnaliz, setPreAnaliz] = useState(null);

  useEffect(() => {
    axios.get(`https://centralclinic.az/api/preperation-for-analysis/az`)
      .then(response => {
        setPreAnaliz(response.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
  }, ['az']);

  if (!preAnaliz) {
    return <div >Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>Central Clinic | Sidik nümunəsi</title>
        <meta name="description" content="Central Clinic 2022ci ildən etibarən Xırdalanda fəaliyyət göstərir." />
        {/* <meta name="keywords" content="Xirdalanda, Central Clinic,  Xırdalanda, Xırdalan, Xirdalan Tibb Mərkəzi " /> */}
      </Helmet>
      {parse(preAnaliz.urine)}
    </div>
  )
}

export default PreparationUrine