import React from 'react'

function YoutubeVideo(props) {
    const id = props.link.slice(props.link.indexOf('=')+1)
    return (
        <div className='YoutubeVideo'>
            <iframe
                src={`https://www.youtube.com/embed/${id}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
  )
}

export default YoutubeVideo