import React, { useEffect, useState } from 'react'

import axios from "axios"

import MyLink from './MyLink'

//style
import './../style/component/hamburger.scss'
import HambugerLanguages from './HambugerLanguages'
function Hamburger() {
    const [mainCat, setMainCat] = useState([])
    const [subCat, setSubCat] = useState([])
    const [show, setShow] = useState(false)
    
    useEffect(() => {
        axios.get(`https://centralclinic.az/api/main-category/az`)
            .then(response => {
                setMainCat(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, ['az'])

    useEffect(() => {
        axios.get(`https://centralclinic.az/api/sub-category/az`)
            .then(response => {
                setSubCat(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, ['az'])

    return (
        <div className='hamburger'>
            <div onClick={() => setShow(!show)} className='hamburger-button'>
                {show ? null :
                    <div>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>}
            </div>
            {show ?
                <div className="hamburger-menu">
                    {/* <HambugerLanguages setShow={setShow} /> */}
                    <a href="tel:+994 50 777 69 99" className='hamburger-call-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z" /></svg>
                        <p>+994 50 777 6 999</p>
                    </a>
                    <svg onClick={() => setShow(!show)} className='cancel' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" /></svg>
                    {
                        mainCat.map(main => {
                            if (main.is_home === false) {
                                return (<MyLink main={main} subCat={subCat} hamShow={setShow} />)
                            }
                        })
                    }
                </div>
                : null
            }
        </div>
    )
}

export default Hamburger