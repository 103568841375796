import { BrowserRouter, Route, Routes } from "react-router-dom"
import { HelmetProvider } from 'react-helmet-async';
import axios from "axios";

import Header from './component/Header';
import Footer from "./component/Footer";
import Home from "./page/Home";
import OnlineRegister from "./page/OnlineRegister";
import CheckUp from "./page/CheckUp";
import ServiceAtHome from "./page/ServiceAtHome";
import Doctors from "./page/Doctors";
import Analysis from "./page/Analysis";
import Tests from "./component/Tests";
import LabSections from "./component/LabSections";
import OrgansPackages from "./component/OrgansPackages";
import DoctorPage from "./page/DoctorPage";
import OutletDoctorsPage from "./page/OutletDoctorsPage";
import OutletPolyclinicPage from "./page/OutletPolyclinicPage";
import Polyclinic from "./page/Polyclinic";
import Laboratory from "./page/Laboratory";
import PreparationAnalyses from "./page/PreparationAnalyses";
import Department from "./page/Department";
import Basket from "./page/Basket";
import WhatsAppIcon from "./component/WhatsAppIcon";
import PreparationBlood from "./component/PreparationBlood";
import PreparationUrine from "./component/PreparationUrine";
import PreparationFeces from "./component/PreparationFeces";
import AboutUs from "./page/AboutUs";
import AboutLab from "./component/AboutLab";
import EResult from "./page/EResult";

//style
import './App.scss';
import CheckUpPage from "./page/CheckUpPage";

function App() {

  return (
    <div className="App">
      <HelmetProvider>
        <BrowserRouter>
          <Header />
          <WhatsAppIcon />
          <Routes>
            <Route path="/" element={<Home />} />


            <Route index element={<Home />} />

            <Route path='/about-us' element={<AboutUs />} />


            <Route path='/register' element={<OnlineRegister />} />


            <Route path='/basket' element={<Basket />} />


            <Route path='/checkup'>
              <Route index element={<CheckUp  />} />
              <Route path="/checkup/:checkupSlug" element={<CheckUpPage />} />
            </Route>

            <Route path='/e-result' element={<EResult />} />


            <Route path='/checkup/:check' element={<CheckUp />} />


            <Route path='/service-at-home' element={<ServiceAtHome />} />


            <Route path='/doctors' element={<OutletDoctorsPage />}>
              <Route index element={<Doctors />} />
              <Route path="/doctors/:doctorSlug" element={<DoctorPage />} />
            </Route>

            <Route path='/polyclinic' element={<OutletPolyclinicPage />}>
              <Route index element={<Polyclinic />} />
              <Route path="/polyclinic/:department" element={<Department />} />
            </Route>

            <Route path='/laboratory' element={<Laboratory />}>
              <Route index element={<PreparationAnalyses />} />
              <Route path="/laboratory/about-laboratory" element={<AboutLab />} />

              <Route path="/laboratory/preparation-for-analyses" element={<PreparationAnalyses />}>
                <Route path="/laboratory/preparation-for-analyses/qan-numunesi" element={<PreparationBlood />} />
                <Route path="/laboratory/preparation-for-analyses/sidik-numunesi" element={<PreparationUrine />} />
                <Route path="/laboratory/preparation-for-analyses/necis-numunesi" element={<PreparationFeces />} />
              </Route>

              <Route path='/laboratory/analysis' element={<Analysis />} >
                <Route index element={<Tests />} />
                <Route path='/laboratory/analysis/tests' index element={<Tests />} />
                <Route path='/laboratory/analysis/lab_sections' index element={<LabSections />} />
                <Route path='/laboratory/analysis/organs_packages' index element={<OrgansPackages />} />
              </Route>

            </Route>


          </Routes>
          <Footer />
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
