import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import parse from 'html-react-parser';

//style
import './../style/component/doctorhome.scss';

function DoctorHome() {
  const [doctors, setDoctors] = useState([]);
  let counter = 0;
  let timer;

  useEffect(() => {
    axios.get(`https://centralclinic.az/api/doctor/az`)
      .then(response => {
        setDoctors(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let slider = document.getElementsByClassName('doctorHomeCard')
    timer = setInterval(() => {
      if (window.location.pathname === '/') {
        slider[counter].classList.remove("display");
        if (counter >= 3) { counter = 0; }
        else { counter++; }
        slider[counter].classList.add("display");
      }
    }, 4000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className='DoctorHome'>
      <div className='doctorlist'>
        {doctors.map((doctor, index) => {
          if (doctor.is_home) {
            return (
              <div
                className={`doctorHomeCard ${index === 0 ? 'display' : ''}`}
                key={doctor.id}
              >
                <img className='image' src={doctor.main_image} alt={doctor.slug} />
                <div className='info'>
                  <Link to={`/doctors/${doctor.slug}`}>
                    <h1>{doctor.full_name}</h1>
                    <h3>{doctor.speciality}</h3>
                    {parse(doctor.main_text)}
                  </Link>
                  <Link to={'/doctors'} className='button'>
                    <p>Bütün Həkimlər</p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                      <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                    </svg>
                  </Link>
                </div>

              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default DoctorHome;
