import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Helmet } from "react-helmet-async";

import AnalysisDetails from './AnalysisDetails'

//style
import './../style/component/labsections.scss'

function LabSections() {
    const [searchParams, setSearchParams] = useSearchParams()
    const sections = [
        'Hematoloji analizlər',
        'Klinik analizlər',
        'Koaquloqramma',
        'Biokimyəvi analizlər',
        'Hormonal analizlər',
        'İmmunoloji analizlər',
        'Autoimmun markerlər',
        'Allergik analizlər',
        'Dərman monitorinqi',
        'Onkomarkerlər',
        'İnfeksiyalar',
        'COVİD-19',
        'PZR analizlər',
        'Genetik analizlər',
        'Bakterioloji analizlər',
        'Histoloji və sitoloji analizlər'
    ]

    const show = (searchParams.get('section') !== '' && searchParams.get('section') !== null)

    function activeStyled(val) {
        if (val === searchParams.get('section')) {
            return ('button active')
        }
        else {
            return ('button')
        }
    }

    return (
        <div className='LabSections'>
              <Helmet>
                <title>Central Clinic | Laboratoriya </title>
                <meta name="description" content="Central Clinic 2022ci ildən etibarən Xırdalanda fəaliyyət göstərir." />
                {/* <meta name="keywords" content="Xirdalanda, Central Clinic,  Xırdalanda, Xırdalan, Xirdalan Tibb Mərkəzi " /> */}
            </Helmet>
            <div className="buttons">
                {
                    sections.map(section => {
                        return (
                            <div onClick={() => {
                                setSearchParams({ section: section })
                            }} className={activeStyled(section)}>
                                {section}
                            </div>

                        )
                    })
                }


            </div>

            {show ? <AnalysisDetails /> : null}

        </div>
    )
}

export default LabSections