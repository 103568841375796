import React, { useState } from 'react'
import { Link  } from "react-router-dom"


function MyLink(props) {
    const [show, setShow] = useState(false)
    const subCats = []

    props.subCat.map(sub => {
        if (sub.main_category === props.main.id)
            subCats.push(sub)
    })

    function changeClass() {
        if (subCats.length !== 0) {
            return ('arrow')
        }
        else {
            return ('arrow dispay-none')
        }
    }

    return (
        <div className='link'>
            <Link onClick={() => props.hamShow(false)} to={`/${props.main.slug}`}>{props.main.title}</Link>

            <div onClick={() => setShow(!show)} className={changeClass()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M169.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 274.7 54.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
            </div>
            
            {show ?
                <div className='subLink' onClick={() => props.hamShow(false)}>
                    {subCats.map((sub, index) => {
                        if (index >= 3) { return (null) }
                        return (
                            <Link to={`/${props.main.slug}/${sub.slug}`}>{sub.title}</Link>
                        )
                    })}
                </div>
                : null
            }
        </div>
    )
}

export default MyLink