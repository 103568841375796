import React, { useState } from 'react'

function BiomaterialType(props) {
    const [selectedOption, setSelectedOption] = useState(props.data[0]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        props.setType(selectedOption)
    };
    
    return (
        <select value={selectedOption} onChange={handleOptionChange}>
            {props.data.map(arr => {
                return (
                    <option value={arr}>
                        {arr}
                    </option>
                )
            })}
        </select>
    )
}

export default BiomaterialType