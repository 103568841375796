import React from 'react'
import { Helmet } from "react-helmet-async";

//style
import './../style/component/aboutlab.scss'

function AboutLab() {
    return (
        <div className='aboutLab'>
              <Helmet>
                <title>Central Clinic | Laboratoriya haqqında</title>
                <meta name="description" content="Central Clinic 2022ci ildən etibarən Xırdalanda fəaliyyət göstərir." />
                {/* <meta name="keywords" content="Xirdalanda, Central Clinic,  Xırdalanda, Xırdalan, Xirdalan Tibb Mərkəzi " /> */}
            </Helmet>
            <div className="container">
                <p>
                    “Central Clinic “Tibb Mərkəzi olaraq təqdim etdiyimiz bütün Laborator xidmətlərlə bağlı elmi araşdırmaları təqib edərək töhfə vermək və davamlı inkişaf edən texnologiyalarla bu sahədə ən son yenilikləri pasientlərimizə təqdim etmək əsas prioritetimizdir.
                </p>
                <p>
                    Yalnız beynəlxalq miqyasda etibarlı və sübut edilmiş laborator üsullardan istifadə edərək, bütün laborator test xidmətlərində cəmiyyətin məlumatlılığını artırmaq, etibarlı, keyfiyyətli və daim yenilikçi, pasient sağlamlığı və məmnuniyyətini önə çəkən xidmətlər təqdim etmək Laboratoriyamızın əsas hədəfidir.
                </p>
            </div>
        </div>
    )
}

export default AboutLab