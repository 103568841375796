import React from 'react'
import { Helmet } from "react-helmet-async";

//style
import './../style/page/serviceathome.scss'

function ServiceAtHome() {
    return (
        <div className='ServiceAtHome'>
            <Helmet>
                <title>Central Clinic | Evinizdə xidmət</title>
                <meta name="description" content="Evdə tibbi xidmət" />
                <meta name="keywords" content="Evdə tibbi xidmət , Evdə tibb xidməti , ÜNVANDA TİBBİ XİDMƏT , Cental Clinic , Laborator müayinələr , müayinə , Check Up müayinələr , Analiz nəticələri , Fizioterapiya Xidməti , Səyyar Anestezioloji Xidmət" />
            </Helmet>
            <div className="container">
                <h2>Evinizdə xidmət</h2>
                <div className='text'>
                    <p>Bəzən olduğumuz məkanı tərk edə bilmirik. Səhhətimiz də zamana və məkana tabe olmur. Belə olan halda ən böyük köməyimiz tibbi xidmətin yanımıza - evimizə və ya olduğumuz məkana gəlməsidir.</p>
                    <p>Cental Clinicin-in hər an və hər ünvanda pasiyentlərinin sağlamlığının keşiyində durmaq üçün ÜNVANDA TİBBİ XİDMƏT layihəsi ilə pasiyentlərinin yanındadır. Xidmət həm də xəstəxanada növbə gözləməyə, marşrut istiqamətini dəyişməyə, tıxacda qalmağa, komfortunu pozmağa ehtiyac olmadan rahat və komfortlu tibbi xidmət almaq üçün ən optimal üsuldur. Bir zənglə Cental Clinic-in keyfiyyətli tibbi xidməti olduğunuz ünvana gələcək.</p>

                    <h4>Ünvanda müayinə daxilində aşağıdakı xidmətlər göstərilir:</h4>
                    <ul>
                        <li>Laborator müayinələr</li>
                        <li>Həkim müayinələri</li>
                        <li>İnstrumental-Diaqnostik müayinələr (USM, RENTGEN, EKQ, EXO-KQ, EEQ, HOLTER, EMQ)</li>
                        <li>Ambulator xidmətlər(sarğılar, infuziyalar, inhalyasiyalar)</li>
                        <li>Check Up müayinələr</li>
                        <li>Fizioterapiya Xidməti</li>
                        <li>Səyyar Anestezioloji Xidmət</li>
                        <li>Audiometriya Xidməti</li>
                        <li>Analiz nəticələrinin ünvana çatdırılması</li>
                    </ul>

                    <div className='contact'>
                        <div>
                            <p><strong>Çağrı mərkəzi:</strong></p>
                            <a href="tel:+994 50 777 69 99">+994 50 777 69 99</a>
                        </div>
                        <div>
                            <p><strong>Əlaqə:</strong></p>
                            <a href="tel:+994 50 777 69 99">+994 50 777 69 99</a>
                        </div>
                        <div>
                            
                            <p><strong>Whatsapp dəstək:</strong></p>
                            <a href="tel:+994 50 777 69 99">+994 50 777 69 99</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceAtHome